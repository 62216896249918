import React from 'react';
import "./CV.css";
import cvPDF from "./CagatayCanKaraahmetoglu.pdf"

function CV() {

  return (
    <React.Fragment>
      <div id="cv-container">
        <div id="pdf-container">
          <embed src={cvPDF} height="100%" width="100%" type="application/pdf" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default CV;
