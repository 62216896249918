import React from "react";
import "./Main.css";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import CV from "./CV";
import Contact from "./Contact";
import Projects from "./Projects";
import Footer from "./Footer";

function Main() {
  return (
    <React.Fragment>
      <section id="main-container">
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/cv" exact component={CV}></Route>
          <Route path="/projects" exact component={Projects}></Route>
          <Route path="/contact" exact component={Contact}></Route>
        </Switch>
        <div id="footer">
          <Footer></Footer>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Main;
