import React from "react";
import "./Home.css";

function Home() {
  return (
    <React.Fragment>
      <div id="home-container">
        <div id="home-header">
          Hi, I'm
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://forvo.com/word/can/#tr"
            title="Turkish pronounciation"
          >
            {" "}
            <u> Can</u>,
          </a>
        </div>
        <div id="home-subheader">a jack of all trades software engineer.</div>
      </div>
    </React.Fragment>
  );
}

export default Home;
