import React from "react";
import "./Sidebar.css";
import Footer from "./Footer.js";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <React.Fragment>
      <div id="sidebar-container">
        <div id="side-nav">
          <h1 id="name">Çağatay Can Karaahmetoğlu</h1>
          <h2 id="title">Software Developer</h2>

          <div id="sidebar-routes">
            <div className="tab-title">
              <Link to="/">Home</Link>
            </div>
            <div className="tab-title">
              {" "}
              <Link to="/cv">CV</Link>
            </div>
            <div className="tab-title">
              {" "}
              <Link to="/projects">Projects</Link>
            </div>
            <div className="tab-title">
              {" "}
              <Link to="/contact">Contact</Link>
            </div>
          </div>
        </div>
        <div id="footer">
          <Footer></Footer>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;
