import React from "react";
import "./Projects.css";

function Projects() {
  return (
    <React.Fragment>
      <div id="projects-container"></div>
    </React.Fragment>
  );
}

export default Projects;
