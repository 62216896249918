import React from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";

function Contact() {
  return (
    <React.Fragment>
      <div id="contact-introduction">
        <div id="contact-header">Contact</div>
        <div id="contact-subheader">
          I am available for hire and open to any ideas of cooperation.
        </div>
        <div id="contact-list">
          <h3>
            E-mail:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:cagatay.can.k@gmail.com"
              title="Mail: cagatay.can.k@gmail.com"
            >
              <FontAwesomeIcon icon={faEnvelope} /> cagatay.can.k@gmail.com
            </a>
          </h3>
          <h3>
            Linkedin:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/cagataycan/"
              title="Linkedin: cagataycan"
            >
              <FontAwesomeIcon icon={faLinkedin} /> CagatayCan
            </a>
          </h3>
          <h3>
            Github:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/CagatayCanK"
              title="Github: CagatayCanK"
            >
              <FontAwesomeIcon icon={faGithubSquare} /> CagatayCanK
            </a>
          </h3>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Contact;
