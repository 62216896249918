import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <React.Fragment>
      <div id="footer-container">
        <div id="social-icons">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:cagatay.can.k@gmail.com"
            title="Mail: cagatay.can.k@gmail.com"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/cagataycan/"
            title="Linkedin: cagataycan"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/CagatayCanK"
            title="Github: CagatayCanK"
          >
            <FontAwesomeIcon icon={faGithubSquare} />
          </a>
        </div>
        <div id="disclaimer">
          This website built with ReactJS and hosted on Github
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
