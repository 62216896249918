import React from "react";
import "./App.css";
import Sidebar from "./components/Sidebar.js";
import Main from "./components/Main.js";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <React.Fragment>
      <Router>
        <div className="app-container">
          <Sidebar></Sidebar>
          <Main></Main>
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
